import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import * as C from '../components'
import Intro from '../shop/Intro'
import Wines from '../shop/Wines'

const Shop: React.SFC = products => (
  <C.App>
    <Intro />
    <Wines products={products} />
    <C.JoinCommunity />
  </C.App>
)

// export default Shop
export const ProductsQ = graphql`
  query {
    allContentfulCatalog {
      edges {
        node {
          product {
            id
            storefrontId
            variantId
            productInformation {
              ... on ContentfulProduct {
                ...ProductFragment
              }
              ... on ContentfulBundle {
                ...BundleFragment
              }
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={ProductsQ}
    render={data => (
      <Shop products={data.allContentfulCatalog.edges[0].node.product} />
    )}
  />
)
