import * as React from 'react'
import styled from 'styled-components'
import bg from '../assets/shop-intro-bg.jpg'
import * as S from '../styles'

const Container = styled(S.Section)`
  padding: 160px 0 60px 0;
  background: url(${bg}) center / cover no-repeat;
`

const Intro: React.SFC<{}> = () => {
  return (
    <Container>
      <S.Wrapper className="small">
        <div>
          <S.Heading as="h2" className="large">
            Shop our wines
          </S.Heading>
          <S.Text style={{ color: 'white', marginBottom: '2em' }}>
            We focus on Sauvignon Blanc and more limited bottlings of Chenin
            Blanc, Cabernet Franc and Rose and produces around 10,000 cases
            annually. Each vintage, the quest for purity, elegance, and balance
            in every wine is paramount.
          </S.Text>
        </div>
      </S.Wrapper>
    </Container>
  )
}

export default Intro
