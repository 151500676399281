import * as React from 'react'
import * as C from '../components'
import * as S from '../styles'
import track from '../track'
import Filter from './Filter'

interface Props {
  products: any
}

const Wines: React.SFC<Props> = ({ products }) => {
  const productArray = products.products
  const [filter, setFilter] = React.useState('all')
  const filtered =
    filter === 'all'
      ? productArray
      : productArray.filter(p => p.productInformation.varietal.color === filter)
  const handleFilterChange = value => {
    setFilter(value)
    track('Product List Filtered', {
      filteredBy: value,
    })
  }

  return (
    <S.Section className="sub  center" style={{ paddingTop: 45 }}>
      <S.Wrapper className="small">
        <Filter value={filter} onChange={handleFilterChange} />
        <div>
          <S.Grid style={{ justifyContent: 'flex-start' }}>
            {filtered.map(p => (
              <S.GridItem
                key={p.variantId}
                width="33.3%"
                breakpoints={{
                  767: '50%',
                  479: '100%',
                }}>
                <C.ProductItem
                  variantId={p.variantId}
                  soldOut={p.productInformation.soldOut}
                  slug={p.productInformation.slug}
                  name={p.productInformation.name}
                  price={p.productInformation.price}
                  regularPrice={p.productInformation.regularPrice}
                  image={p.productInformation.mainImage.fluid.src}
                />
              </S.GridItem>
            ))}
          </S.Grid>
        </div>
      </S.Wrapper>
    </S.Section>
  )
}

export default Wines
