import * as React from 'react'
import styled from 'styled-components'

import downArrow from '../assets/down-arrow.svg'
import * as S from '../styles'

const FilterButton = styled(S.Button)<{
  selected?: boolean
}>`
  background: ${p => p.selected && '#002821'};
  color: ${p => p.selected && 'white'};
  margin-right: 16px;
`

const Desktop = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 45px;

  @media (max-width: 767px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`

const Select = styled(S.Button)`
  position: relative;
  width: 100%;
  height: 52px;
  margin-bottom: 45px;
  background: url(${downArrow}) calc(100% - 16px) center no-repeat;

  select {
    display: block;
    outline: none;
    -webkit-appearance: none;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    font-size: 17px;
    font-weight: 900;
    padding: 0.9em 1.5em;
    height: 52px;
  }
`

interface IFilterProps {
  value: string
  onChange: (value) => void
}

const Filter: React.SFC<IFilterProps> = ({ value, onChange }) => {
  return (
    <>
      <Desktop>
        <FilterButton
          selected={value === 'all'}
          onClick={() => onChange('all')}>
          All
        </FilterButton>
        <FilterButton
          selected={value === 'white'}
          onClick={() => onChange('white')}>
          White
        </FilterButton>
        <FilterButton
          selected={value === 'rose'}
          onClick={() => onChange('rose')}>
          Rosé
        </FilterButton>
        <FilterButton
          selected={value === 'red'}
          onClick={() => onChange('red')}>
          Red
        </FilterButton>
      </Desktop>
      <Mobile>
        <Select>
          <select value={value} onChange={e => onChange(e.currentTarget.value)}>
            <option value="all">All Wines</option>
            <option value="red">Red</option>
            <option value="white">White</option>
            <option value="rose">Rosé</option>
          </select>
        </Select>
      </Mobile>
    </>
  )
}

export default Filter
